/****************************/
/*My Music Page*/
/****************************/
@import "tileAnimations.css";

/* These variables are just initial and will be changed in the my-music.jsx page*/
:root {
    --numOfMusicTileCols: 6;
    --tempo: 80;
    --evenMusicTileColor: red;
    --oddMusicTileColor: blue;
    --firstBoxShadow: blue;
    --secondBoxShadow: red;
    --animationIterationCount: infinite;
    --appliedAnimations: ;
    --animationDurations: ;
    --animationTimingFunction: ;
    --mobileSlideDirection: ;
}


.music-tile {
    border: none;
    border-radius: 5px;
    height: calc(100vw/var(--numOfMusicTileCols));
    width: 100%;
}

.music-tile:hover {
    filter: brightness(.9);
}

.selectorList input.form-check-input:checked {
    background-color: gray !important;
    filter: hue-rotate(202deg);
}

.animation>.music-tile {
    animation-name: var(--appliedAnimations);
    animation-duration: var(--animationDurations);
    animation-iteration-count: var(--animationIterationCount);
    animation-timing-function: var(--animationTimingFunction);
}

.animation>.music-tile:nth-child(odd) {
    animation-direction: alternate;
}

.animation>.music-tile:nth-child(even) {
    animation-direction: alternate-reverse;
}

.music-tile:nth-child(odd) {
    background-color: var(--oddMusicTileColor);
}

.music-tile:nth-child(even) {
    background-color: var(--evenMusicTileColor);
}

.paused>.music-tile {
    animation-play-state: paused;
}

.play>.music-tile {
    animation-play-state: running !important;
}

#music-tile-wrapper {
    display: grid;
    max-height: 100%;
    background-color: white;
    grid-template-columns: repeat(var(--numOfMusicTileCols), 2fr);
    background-color: var(--backgroundColor);
    overflow: hidden;
}

@media only screen and (max-width: 250px) {}

.arrow-icon {
    margin-bottom: 10px;
    transition: all .5s ease-in-out;
}

.arrow-icon-upside-down {
    transform: rotate(180deg);
    transition: all .5s ease-in-out;
}

.volume-wrapper {
    margin-top: 40px;
    margin-left: 10px;
    float: left;
    background: var(--backgroundColor);
    border-radius: 25px;
    padding: 7px;
    padding-bottom: 2px;
    width: 60px;
    height: 60px;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.volume-wrapper:hover {
    width: 200px;
    height: 60px;
}

.volume-icon {
    padding-top: 5px;
    float: left;
    width: 45px;
    height: 45px;
    cursor: pointer;
}

#volume-range {
    filter: hue-rotate(18deg);
}

.volume-range-wrapper {
    padding-top: 7px;
}

.selector-animation-wrapper {
    background: #e5dcdc;
    padding-left: 5px;
}

#selector-title-animation {
    border-bottom: 5px;
    border-color: black;
}

.music-ctrl {
    position: sticky;
    float: left;
}

#music-animation-ctrl-wrapper:hover {
    opacity: 1;
}

#music-animation-ctrl-wrapper.open {
    max-height: calc(100vh - 15px);
    height: 81%;
    transition: all .3s ease-in-out;
}

#music-animation-ctrl-wrapper {
    opacity: .25;
    height: 218px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    color: black;
    padding: 10px;
    transition: all .3s ease-in-out;
}

.music-ctrl-btn {
    cursor: pointer;
    width: 125px;
    margin-top: 30px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mobile-more-btn {
    display: none;
    left: 50%;
    transform: translateX(-50%);
}

.selector {
    font-size: 25px;
    max-height: 150px;
    /* width:100%; */
    max-width: 400px;
    min-width: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-track-color: var(--backgroundColor);
    scrollbar-color: var(--backgroundColor) gray;
    scroll-snap-type: proximity;
    border-radius: 0px;
    scroll-behavior: smooth;
}

.selector-label {
    font-size: 50px;
    margin-top: 70px;
    margin-left: 25px;
}

.selector-list-wrapper {
    display: inline-table;
    margin: 5px;
    width: 300px;

}

#music-ctrl-wrapper {
    width: 1085px;
    display: inline-block;
}

#music-animation-ctrl-wrapper:hover {
    background-color: rgba(197, 197, 221, 0.658);
}

.song-title {
    font-size: 50px;
    color: black;
    text-shadow: 50px white;
    bottom: 5%;
    margin-left: 0px;
    margin-top: 30px;
    min-width: 40%;
}

.selectorList {
    max-height: 200px;
}

.selector-title {
    padding-top: 5px;
    background-color: var(--backgroundColor);
}

.selector-wrapper {
    float: right;
    max-width: fit-content;
}

.song-img {
    height: 150px;
    width: 150px;
    border-style: groove;
    border-width: 5px;
    border-color: black;
    margin-right: 30px;
}


.play-pause-container {
    margin-top: 18px;
    margin-left: 60px;
    background: var(--backgroundColor);
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 18px 50px -12px rgba(90, 83, 93, 0.44);
}

.playpause-chk {
    appearance: none;

    +label {
        display: block;
        height: 36px;
        width: 0;
        background: var(--backgroundColor);
        border-right: 30px double #000000;
        transition: 0.1s transform ease;
        position: relative;
        cursor: pointer;

    }

    &:checked+label {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 36px 20px;
        border-color: transparent transparent #130f40 transparent;
        transform: rotate(90deg);
        cursor: pointer;
    }
}

.playpause-chk-icon {
    appearance: none;

    +label::before {
        content: "❚❚";
        display: block;
        font-size: 48px;
        position: relative;
        color: #130f40;
        transition: 0.1s transform ease;
        transform: rotate(-180deg);
    }

    &:checked+label::before {
        content: "►";
        transform: rotate(0);

    }
}

.loading-spinner {
    position: absolute !important;
    margin-left: auto;
    color: black;
    top: 50vh;
    left: 50vw;
    transform: translateX(-50%);
}

.background {
    opacity: .5;
    background-color: whitesmoke;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
}


@media (max-width:2080px) and (min-width:628px) {

    .selector-wrapper,
    #music-ctrl-wrapper {
        margin: auto;
        float: none;
    }

    #music-animation-ctrl-wrapper {
        height: 169px;
    }

    #music-animation-ctrl-wrapper.open {
        height: 390px;
        transition: all .3s ease-in-out;
    }

    .mobile-more-btn {
        display: initial !important;
        border-radius: 5px;
        border-bottom: none;
        opacity: .75;
        position: absolute;
        width: 200px;
        margin-top: -28px;
        height: 40px;
    }
}

@media (max-width:1010px) and (min-width:628px) {
    #music-ctrl-wrapper {
        width: 100%;
    }

    #music-animation-ctrl-wrapper {
        height: 136px;
    }

    #music-animation-ctrl-wrapper.open {
        height: 549px;
        transition: all .3s ease-in-out;
    }

    .song-title {
        font-size: 30px;
        margin-top: 50px;
    }

    div.play-pause-container {
        margin-left: 10px;
        margin-top: 35px;
        cursor: pointer;
        width: 70px;
        height: 70px;
    }

    img.music-ctrl-btn {
        margin-top: 15px;
        cursor: pointer;
        width: 64px;
    }

    img.song-img {
        width: 100px;
        height: 100px;
        margin: 20px 20px 0px 5px;
    }

    #music-ctrl-wrapper {
        float: left;
    }

    .volume-wrapper {
        margin-top: 40px;
    }
}


@media (max-width: 628px) {
    #music-animation-ctrl-wrapper.open {
        max-height: calc(100vh - 15px);
        height: 81%;
        transition: all .3s ease-in-out;
    }

    #music-ctrl-wrapper {
        width: 100%;
    }

    #music-animation-ctrl-wrapper {
        height: 110px
    }

    img.music-ctrl-btn {
        margin-top: 15px;
        cursor: pointer;
        width: 64px;
    }

    div.play-pause-container {
        margin-left: 0px;
        margin-top: 10px;
        cursor: pointer;
        width: 70px;
        height: 70px;
    }

    span.song-title {
        font-size: 25px;
        max-height: 50px;
        margin-top: 20px !important;
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
        width: 60%;
        line-height: 24px;
    }

    img.song-img {
        width: 50px;
        height: 50px;
        margin: 20px 0 0 5px;
    }

    .selector-wrapper>.selector {
        margin: 0px;
    }

    .selector-wrapper {
        margin-top: 10px;
    }

    .mobile-more-btn {
        display: initial !important;
        border-radius: 5px;
        border-bottom: none;
        opacity: .75;
        position: absolute;
        width: 150px;
        margin-top: -28px;
        height: 28px;
    }

    .volume-wrapper {
        display: none;
    }
}

@media (max-height:886px) {
    #music-animation-ctrl-wrapper.open {
        height: 94%;
    }
}

@media (max-height:750px) {
    .selector-list-wrapper {
        height: 160px;
        display: grid;
        width: 325px;
    }

    .selector-wrapper {
        margin-left: auto;
        margin-right: auto;
        float: unset;
    }

    #music-animation-ctrl-wrapper.open {
        height: calc(100vh - 15px);
    }
}