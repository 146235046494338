:root {
  --left: 0;
  --top: 0; 
}

.App {
  text-align: center;
  background-color:black;
  background-image:
  radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
  radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
  radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
  radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
  
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  color:aliceblue;
  /*overflow:hidden;*/
  min-height: 100vh;
  font-style: normal;
  font-weight: 100 !important;
  font-size:24px;
}
*{
  margin:0;
  padding:0;
}
h1,h2,h3,h4,h5{
  font-weight: 100 !important;
  font-size: 100px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#my-cursor{
  height: 25px;
  width:25px;
  position: absolute;
  border-radius: 25px;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0.164) 0%, rgba(0, 213, 255, 0.082) 100%, rgba(2, 0, 36, 0.068) 100%);
   pointer-events: none;
}
.jersey-15-regular {
  font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.josefin-sans-fancy {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.indie-flower-regular {
  font-family: "Indie Flower", cursive;
  font-weight: 400;
  font-style: normal;
}
/* .jacquard-12-regular */
h1 {
  font-family: "Jacquard 12", system-ui;
  font-weight: 400;
  font-style: normal;
}

.jacquard-12-regular{
  font-family: "Jacquard 12", system-ui;
  font-weight: 400;
  font-style: normal;
}


.open-sans-normal {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}


body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
