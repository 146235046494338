:root {
    --combinedTransformStart: ;
    --combinedTransformMiddle: ;
    --combinedTransformEnd: ;
}

@keyframes combinedTransforms {
    0% {
        transform: var(--combinedTransformStart);
    }

    50% {
        transform: var(--combinedTransformMiddle);
    }

    100% {
        transform: var(--combinedTransformEnd);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0px 11px 20px 0px var(--firstBoxShadow);
    }

    100% {
        box-shadow: 0px -11px 20px 0px var(--secondBoxShadow);
    }
}

@keyframes squareToCircle {
    0% {
        border-radius: 0px;
    }

    100% {
        border-radius: calc(100vw/var(--numOfMusicTileCols));
    }
}

@keyframes colorChange {
    0% {
        background-color: var(--evenMusicTileColor);
    }

    100% {
        background-color: var(--oddMusicTileColor);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        margin-bottom: -333px;
    }

    to {
        margin-bottom: 0px;
    }
}

@keyframes flicker {

    0%,
    80%,
    100% {
        opacity: 1;
    }

    40%,
    60% {
        opacity: 0.74;
    }
}